import { useContext, useMemo, useEffect } from "react"
import { useIsFetching, useIsMutating } from "@tanstack/react-query"

import PartnersTable from "./PartnersTable"
import { eligibleTableColumns } from "./PartnersTable/helpers"
import PartnersEligibleLeadsContact from "./PartnersEligibleLeadsContact"
import PartnersEligibleLeadsUnlockDisqualify from "./PartnersEligibleLeadsUnlockDisqualify"
import PartnersAccount from "./PartnersAccount"
import PartnersAccountMap from "./PartnersAccountMap"
import { Spinner } from "../../components/Spinner"
import { PartnersContext } from "../../context/PartnersContext"
import { ELIGIBLE_LEADS, PARTNERS_ACTIONS } from "../../shared/constants"
import { usePartnersTableResultsText } from "../../hooks/usePartnersTableReusltsText"

const PartnersEligibleLeads = ({
  eligibleLeads,
  userFirstName,
  account,
  accountStatus,
  reachoutsCounter,
}) => {
  const {
    state: {
      activeTab,
      eligibleActiveRowUnlocked,
      eligibleLeadDataLoaded,
      eligibleLeadSorting,
      eligibleLeadColumnFilters,
      eligibleLeadFilters,
      eligibleLeadFilterFunctions,
    },
    dispatch,
  } = useContext(PartnersContext)

  const isTablePageLoading = useIsMutating({
    mutationKey: ["partners", ELIGIBLE_LEADS],
  })

  const isDataFetching = useIsFetching({
    queryKey: ["partners", ELIGIBLE_LEADS],
  })

  const columns = useMemo(
    () => eligibleTableColumns(eligibleLeadFilterFunctions),
    [eligibleLeadFilterFunctions]
  )

  const resultsText = usePartnersTableResultsText(
    eligibleLeads?.count ?? 0,
    eligibleLeads?.previous ?? null,
    eligibleLeads?.next ?? null,
    eligibleLeads?.results ?? []
  )

  const handleSelectRow = (row) => {
    dispatch({
      type: PARTNERS_ACTIONS.setActiveRow,
      payload: row,
    })
    dispatch({
      type: PARTNERS_ACTIONS.setViewport,
      payload: null,
    })
    dispatch({
      type: PARTNERS_ACTIONS.setEligibleActiveRowUnlocked,
      payload: row !== null && row?.status !== "hidden",
    })
  }

  useEffect(() => {
    if (eligibleLeads?.results && activeTab === ELIGIBLE_LEADS) {
      const firstResult = eligibleLeads.results[0]

      dispatch({
        type: PARTNERS_ACTIONS.setActiveRow,
        payload: firstResult,
      })
      dispatch({
        type: PARTNERS_ACTIONS.setEligibleActiveRowUnlocked,
        payload: firstResult?.status !== "hidden",
      })
    }
  }, [eligibleLeads, dispatch, activeTab])

  if (activeTab !== ELIGIBLE_LEADS) {
    return null
  }

  if (!eligibleLeadDataLoaded) {
    return (
      <div className="bg-gray-0 h-screen flex items-center justify-center">
        <Spinner />
      </div>
    )
  }

  return (
    <div className="grid grid-cols-1 md:gap-6 md:grid-cols-3">
      <div className="col-span-2">
        <div className="w-full card card-content self-start mb-6">
          <p className="text-dusk-500">
            <span className="italic font-semibold">NEW</span> View landowners
            who have been remotely assessed as eligible for your program but
            haven't requested a call. Unlock and message up to{" "}
            {reachoutsCounter.max_reachouts} leads a month to see the
            landowner's property details.
          </p>
        </div>

        <PartnersTable
          data={eligibleLeads.results}
          onRowClick={handleSelectRow}
          filterFunctions={eligibleLeadFilterFunctions}
          setFilterFunctions={(val) => {
            dispatch({
              type: PARTNERS_ACTIONS.setEligibleLeadFilterFunctions,
              payload: val,
            })
          }}
          columnFilters={eligibleLeadColumnFilters}
          setColumnFilters={(val) => {
            dispatch({
              type: PARTNERS_ACTIONS.setEligibleLeadColumnFilters,
              payload: val,
            })
          }}
          filters={eligibleLeadFilters}
          setFilters={(val) =>
            dispatch({
              type: PARTNERS_ACTIONS.setEligibleLeadFilters,
              payload: val,
            })
          }
          columns={columns}
          sorting={eligibleLeadSorting}
          setSorting={(val) =>
            dispatch({
              type: PARTNERS_ACTIONS.setEligibleLeadSorting,
              payload: val,
            })
          }
          resultsText={resultsText}
          next={eligibleLeads.next}
          previous={eligibleLeads.previous}
          paginationEnabled={true}
          isTableLoading={isTablePageLoading === 1 || isDataFetching === 1}
        />

        {eligibleActiveRowUnlocked ? (
          <PartnersAccountMap
            bounds={account?.bounds}
            accountStatus={accountStatus}
          />
        ) : null}
      </div>
      <div className="flex flex-col gap-6">
        <PartnersEligibleLeadsUnlockDisqualify
          action={eligibleActiveRowUnlocked ? "disqualify" : "unlock"}
          handleSelectRow={handleSelectRow}
          reachoutsCounter={reachoutsCounter}
        />

        {eligibleActiveRowUnlocked ? (
          <>
            <PartnersEligibleLeadsContact
              userFirstName={userFirstName}
              reachoutsCounter={reachoutsCounter}
            />

            <PartnersAccount
              unlocked={false}
              account={account}
              accountStatus={accountStatus}
            />
          </>
        ) : null}
      </div>
    </div>
  )
}

export default PartnersEligibleLeads
