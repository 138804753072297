import { Spinner } from "../../components/Spinner"

const PartnersAccountInfo = ({ primaryContact, accountStatus }) => {
  if (accountStatus === "pending") {
    return (
      <div className="w-full card card-content self-start">
        <h4 className="text-overline text-dusk-500 mb-3">
          Primary contact info
        </h4>

        <div className="flex justify-center items-center h-24">
          <Spinner />
        </div>
      </div>
    )
  }

  return (
    <div className="w-full card card-content self-start">
      <h4 className="text-overline text-dusk-500 mb-3">Primary contact info</h4>

      <ul>
        <li>
          <span className="font-semibold">First name - </span>
          {primaryContact?.first_name}
        </li>
        <li>
          <span className="font-semibold">Last name - </span>
          {primaryContact?.last_name}
        </li>
        <li>
          <span className="font-semibold">Email - </span>
          {primaryContact?.email ? (
            <a href={`mailto:${primaryContact?.email}`}>
              {primaryContact?.email}
            </a>
          ) : null}
        </li>
        <li>
          <span className="font-semibold">Phone Number - </span>
          {primaryContact?.phone_number}
        </li>
      </ul>
    </div>
  )
}

export default PartnersAccountInfo
