import { useContext } from "react"
import { useQueryClient } from "@tanstack/react-query"

import SelectMenu from "../../../components/SelectMenu"
import { Toast } from "../../../components/Toast"
import { usePartnersBulkUpdateLandownerStatus } from "../../../hooks"
import { genericErrMsg } from "../../../api/auth"
import { PARTNERS_ACCOUNT_STATUSES } from "../../../shared/constants"
import { PartnersContext } from "../../../context/PartnersContext"
import { heapTrackEvent } from "../../../api/integrations"

const TableBulkActions = ({ selectedRows, onUpdateSuccess }) => {
  const queryClient = useQueryClient()

  const {
    state: { activeRow },
  } = useContext(PartnersContext)

  const { mutateAsync: bulkUpdateLandownerStatus } =
    usePartnersBulkUpdateLandownerStatus(queryClient, {
      onSuccess: () => {
        Toast.success("Statuses have been updated successfully.")
        onUpdateSuccess && onUpdateSuccess()
      },
      onError: (error) => {
        Toast.error(error?.detail || genericErrMsg)
      },
    })

  const handleUpdateStatus = async (selectAction) => {
    let selectedApIds = Object.keys(selectedRows).map((apId) => parseInt(apId))
    if (!selectedApIds.length) {
      selectedApIds = [activeRow.id]
    }
    heapTrackEvent("actions-status-update", {
      number_of_rows: selectedApIds.length,
    })
    await bulkUpdateLandownerStatus({
      aps: selectedApIds,
      status: selectAction.value,
    })
  }

  const dropdownOptions = PARTNERS_ACCOUNT_STATUSES.map((statusOption) => ({
    ...statusOption,
    label: (
      <>
        <span className="font-bold">Update Status:</span>{" "}
        <span className="font-normal">{statusOption.label}</span>
      </>
    ),
    className: `status-update-${statusOption.value} text-left`,
  }))

  return (
    <SelectMenu
      options={dropdownOptions}
      setSelectedOption={handleUpdateStatus}
      label="Actions"
      menuClassName="md:w-max"
      gutter={10}
    />
  )
}

export default TableBulkActions
