import { QueryClient, UseMutationResult } from "@tanstack/react-query"
import { FormikValues } from "formik"

import { useMutationWithRefresh } from "../utils"
import { UseMutationWithRefreshConfig } from "../utils/useMutationWithRefresh"
import { updateAccountProjectReachout } from "../../../api/data"
import { ELIGIBLE_LEADS } from "../../../shared/constants"
import { RowsType, RowType } from "@/types/partners"

export const useUpdateAccountProjectReachout = <
  TData,
  TError,
  TVariables extends FormikValues,
>(
  queryClient: QueryClient,
  apId: number,
  config: UseMutationWithRefreshConfig<TData, TError, TVariables> = {}
): UseMutationResult<TData, TError, TVariables, unknown> => {
  return useMutationWithRefresh<TData, TError, TVariables, unknown>(
    queryClient,
    (values) => updateAccountProjectReachout(apId.toString(), values),
    {
      _onSuccess: () => {
        queryClient.setQueryData(
          ["partners", ELIGIBLE_LEADS],
          (oldData: RowsType) => {
            return oldData.filter((landowner: RowType) => landowner.id !== apId)
          }
        )
        queryClient.invalidateQueries({ queryKey: ["partners"] })
      },
      ...config,
    }
  )
}
