import { QueryClient, UseQueryResult } from "@tanstack/react-query"

import { useQueryWithRefresh } from "../utils"
import { getPartnersInterestedProjects } from "../../../api/data"
import { UseQueryWithRefreshConfig } from "../utils/useQueryWithRefresh"

export const useGetPartnersInterestedProjects = <TData, TError>(
  queryClient: QueryClient,
  apId: number,
  config: UseQueryWithRefreshConfig<TData, TError> = {}
): UseQueryResult<TData, TError> => {
  return useQueryWithRefresh<TData, TError>(
    queryClient,
    ["partners", "ap", apId, "interested_projects"],
    () => getPartnersInterestedProjects(apId.toString()),
    config
  )
}
