import { useQueryClient } from "@tanstack/react-query"
import { faMap } from "@fortawesome/pro-solid-svg-icons"

import IconButton from "../../components/IconButton"
import { Toast } from "../../components/Toast"
import { useGetPartnersLandownerGeodownload } from "../../hooks"
import { genericErrMsg } from "../../api/auth"

const PartnersDownloadPropertyBoundaries = ({ apId }) => {
  const queryClient = useQueryClient()

  const { mutateAsync: boundsGeodownload } = useGetPartnersLandownerGeodownload(
    queryClient,
    apId,
    {
      onSuccess: ({ download_link }) => {
        window.location.href = download_link
      },
      onError: (error) => {
        Toast.error(error?.detail || genericErrMsg)
      },
    }
  )

  return (
    <div className="w-full p-4">
      <IconButton
        onClick={async () => {
          await boundsGeodownload()
        }}
        icon={faMap}
        text="Download Property Boundaries"
        className="leading-6"
      />
    </div>
  )
}
export default PartnersDownloadPropertyBoundaries
