import { useContext } from "react"
import { useQueryClient } from "@tanstack/react-query"

import { useGetPartnersInterestedProjects } from "../../hooks"
import { PartnersContext } from "../../context/PartnersContext"

const PartnersInterestedPrograms = () => {
  const queryClient = useQueryClient()

  const {
    state: { activeRow },
  } = useContext(PartnersContext)

  const { data: interestedProjects } = useGetPartnersInterestedProjects(
    queryClient,
    activeRow?.id,
    {
      enabled: activeRow?.id !== undefined,
    }
  )

  if (interestedProjects === undefined || interestedProjects?.length === 0) {
    return null
  }

  return (
    <div className="w-full card card-content self-start">
      <h4 className="text-overline text-dusk-500 mb-3">Interested Programs</h4>

      <ul>
        {interestedProjects?.map((program) => (
          <li
            key={program}
            className="block text-leaf text-sm font-bold leading-130 tracking-0.14"
          >
            {program}
          </li>
        ))}
      </ul>
    </div>
  )
}

export default PartnersInterestedPrograms
