import { QueryClient, UseMutationResult } from "@tanstack/react-query"
import { FormikValues } from "formik"

import { useMutationWithRefresh } from "../utils"
import { deletePartnerAssignees } from "../../../api/data"
import { UseMutationWithRefreshConfig } from "../utils/useMutationWithRefresh"
import { RowsType } from "@/types/partners"

export const useDeletePartnerAssignees = <
  TData,
  TError,
  TVariables extends FormikValues,
>(
  queryClient: QueryClient,
  apId: number,
  config: UseMutationWithRefreshConfig<TData, TError, TVariables> = {}
): UseMutationResult<TData, TError, TVariables, unknown> => {
  return useMutationWithRefresh<TData, TError, TVariables, unknown>(
    queryClient,
    (values: TVariables) => deletePartnerAssignees(apId.toString(), values),
    {
      _onSuccess: () => {
        queryClient.setQueryData(["partners"], (oldData: RowsType) => {
          return oldData.map((landowner) => {
            if (landowner.id === apId) {
              return { ...landowner, deal_owner: {} }
            }
            return landowner
          })
        })
      },
      ...config,
    }
  )
}
