import { Spinner } from "../../components/Spinner"

const PartnersAccountAttestations = ({ attestations, accountStatus }) => {
  if (attestations?.length === 0) {
    return null
  }

  if (accountStatus === "pending") {
    return (
      <div className="relative w-full card card-content flex flex-col gap-5 self-start overflow-auto max-h-[366px]">
        <h4 className="sticky -top-4 left-0 right-0 bg-cloud-50 text-overline text-dusk-500 p-4 -m-4 mb-0 border-b-1 border-b-cloud z-2">
          Attestations
        </h4>

        <div className="flex justify-center items-center h-24">
          <Spinner />
        </div>
      </div>
    )
  }

  return (
    <div className="relative w-full card card-content flex flex-col gap-5 self-start overflow-auto max-h-[366px]">
      <h4 className="sticky -top-4 left-0 right-0 bg-cloud-50 text-overline text-dusk-500 p-4 -m-4 mb-0 border-b-1 border-b-cloud z-2">
        Attestations
      </h4>

      {attestations?.map((attestation) => (
        <div
          className="card card-content-simple"
          key={attestation.attestation_id}
        >
          <p>
            <span className="font-semibold">Question: </span>{" "}
            {attestation.attestation}
          </p>
          <p>
            <span className="font-semibold">Answer: </span> {attestation.value}
          </p>
        </div>
      ))}
    </div>
  )
}

export default PartnersAccountAttestations
