import { usePopoverState, Popover } from "reakit/Popover"
import { useTooltipState } from "reakit/Tooltip"
import cx from "classnames"

import TableFiltersClose from "./TableFiltersClose"
import TableFiltersReset from "./TableFiltersReset"
import TableFiltersAdd from "./TableFiltersAdd"
import TableFiltersSelectColumn from "./TableFiltersSelectColumn"
import TableFiltersSelectFilterFn from "./TableFiltersSelectFilterFn"
import TableFiltersInput from "./TableFiltersInput"
import TableFiltersRemoveFilter from "./TableFiltersRemoveFilter"
import { FILTER_FNS } from "./constants"

const TableFilters = ({
  allColumns,
  filters,
  setFilters,
  filterFunctions,
  setFilterFunctions,
  resetColumnFilters,
  tableState,
}) => {
  const tooltip = useTooltipState()
  const popover = usePopoverState({
    placement: "bottom-end",
  })

  return (
    <>
      <TableFiltersClose
        popover={popover}
        tooltip={tooltip}
        columnFiltersLength={tableState.columnFilters.length}
      />

      <Popover
        {...popover}
        aria-label="Table filters"
        className="z-50 max-w-full"
      >
        <div className="border border-cloud rounded bg-white shadow">
          <div className="max-h-[288px] overflow-y-auto">
            {filters.map((filter, filterIndex) => {
              const currInput = allColumns.find(
                (column) => column.id === filter
              )

              const wideSelectHideInput =
                currInput.columnDef.filterFn === FILTER_FNS.isEmpty ||
                currInput.columnDef.filterFn === FILTER_FNS.isNotEmpty

              return (
                <div
                  key={filter}
                  className={cx(
                    "px-2 py-1 flex items-center justify-end gap-2",
                    {
                      "pt-2": filterIndex === 0,
                    }
                  )}
                >
                  <p className="block mr-auto text-left w-11 shrink-0">
                    {filterIndex === 0 ? "Where" : "And"}
                  </p>

                  <div className="grid grid-cols-3 gap-2 grow">
                    <TableFiltersSelectColumn
                      filter={filter}
                      filters={filters}
                      setFilters={setFilters}
                      filterIndex={filterIndex}
                      filterFunctions={filterFunctions}
                      setFilterFunctions={setFilterFunctions}
                      currInput={currInput}
                      allColumns={allColumns}
                    />

                    <TableFiltersSelectFilterFn
                      wideSelectHideInput={wideSelectHideInput}
                      filter={filter}
                      filterFunctions={filterFunctions}
                      setFilterFunctions={setFilterFunctions}
                      currInput={currInput}
                    />

                    <TableFiltersInput
                      filter={filter}
                      currInput={currInput}
                      wideSelectHideInput={wideSelectHideInput}
                      columnFiltersLength={tableState.columnFilters.length}
                    />
                  </div>

                  <TableFiltersRemoveFilter
                    filter={filter}
                    filters={filters}
                    currInput={currInput}
                    setFilters={setFilters}
                  />
                </div>
              )
            })}
          </div>

          <TableFiltersAdd
            allColumns={allColumns}
            filters={filters}
            setFilters={setFilters}
          />

          <TableFiltersReset resetColumnFilters={resetColumnFilters} />
        </div>
      </Popover>
    </>
  )
}

export default TableFilters
