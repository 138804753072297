import { QueryClient, UseQueryResult } from "@tanstack/react-query"

import { useQueryWithRefresh } from "../utils"
import { getPartnerAssignees } from "../../../api/data"
import { UseQueryWithRefreshConfig } from "../utils/useQueryWithRefresh"

export const useGetPartnerAssignees = <TData, TError>(
  queryClient: QueryClient,
  config: UseQueryWithRefreshConfig<TData, TError> = {}
): UseQueryResult<TData, TError> => {
  return useQueryWithRefresh<TData, TError>(
    queryClient,
    ["partners", "partner_assignees"],
    () => getPartnerAssignees(),
    config
  )
}
