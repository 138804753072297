const TableFiltersReset = ({ resetColumnFilters }) => (
  <div className="w-[830px] max-w-full text-left p-3 border-t-1 border-t-cloud">
    <button
      className="hover:opacity-80 transition-opacity duration-200"
      onClick={resetColumnFilters}
    >
      Reset Filters
    </button>
  </div>
)

export default TableFiltersReset
