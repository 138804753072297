import { useQueryClient } from "@tanstack/react-query"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faArrowLeft, faArrowRight } from "@fortawesome/pro-solid-svg-icons"
import cx from "classnames"

import { usePartnersTablePagination } from "../../../hooks"

interface TablePaginationTypes {
  previous: string | null
  next: string | null
  enabled: boolean
  isTableLoading: boolean
}

const TablePagination = ({
  previous,
  next,
  enabled = false,
  isTableLoading,
}: TablePaginationTypes) => {
  const queryClient = useQueryClient()

  const { mutateAsync: setTablePage } = usePartnersTablePagination(queryClient)

  const handleSetTablePage = async (page: string) => {
    await setTablePage(page)
  }

  if (!enabled) {
    return null
  }

  return (
    <div className="h-[72px] flex justify-center items-center gap-16">
      <button
        type="button"
        aria-label="previous page"
        className={cx(
          "h-10",
          previous === null || isTableLoading
            ? "pagination-link-disabled"
            : "pagination-link"
        )}
        onClick={() => {
          if (previous) {
            handleSetTablePage(previous)
          }
        }}
        disabled={previous === null || isTableLoading}
      >
        <span className="flex items-center gap-2">
          <span className="pagination-link-icon">
            <FontAwesomeIcon icon={faArrowLeft} />
          </span>

          <span className="text-base font-semibold">Prev page</span>
        </span>
      </button>

      <button
        type="button"
        aria-label="next page"
        className={cx(
          "h-10",
          next === null || isTableLoading
            ? "pagination-link-disabled"
            : "pagination-link"
        )}
        onClick={() => {
          if (next) {
            handleSetTablePage(next)
          }
        }}
        disabled={next === null || isTableLoading}
      >
        <span className="flex items-center gap-2">
          <span className="text-base font-semibold">Next page</span>

          <span className="pagination-link-icon">
            <FontAwesomeIcon icon={faArrowRight} />
          </span>
        </span>
      </button>
    </div>
  )
}

export default TablePagination
