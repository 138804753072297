import { faPlus } from "@fortawesome/pro-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

const TableFiltersAdd = ({ allColumns, filters, setFilters }) => (
  <div className="w-[830px] max-w-full text-left p-3">
    <button
      type="button"
      className="hover:opacity-80 disabled:hover:opacity-100 disabled:text-gray2 transition-opacity duration-200"
      onClick={() => {
        const availableColumns = allColumns.filter(
          (column) => !filters.includes(column.id) && column.id !== "select"
        )
        const newFilter = availableColumns[0].columnDef.accessorKey
        setFilters([...filters, newFilter])
      }}
      disabled={filters.length === allColumns.length}
    >
      <FontAwesomeIcon icon={faPlus} /> Add
    </button>
  </div>
)

export default TableFiltersAdd
