import { useContext } from "react"

import PartnerAccountUpdateStatus from "./PartnersAccountUpdateStatus"
import PartnersAccountInfo from "./PartnersAccountInfo"
import PartnersAccountProfiles from "./PartnersAccountProfiles"
import PartnersAccountAttestations from "./PartnersAccountAttestations"
import PartnersInterestedPrograms from "./PartnersInterestedPrograms"
import { PartnersContext } from "../../context/PartnersContext"
import { QUALIFIED_LEADS } from "../../shared/constants"

const PartnersAccount = ({
  unlocked = true,
  account,
  accountStatus,
  selectedRows,
  setRowSelection,
}) => {
  const {
    state: { activeTab },
  } = useContext(PartnersContext)

  return (
    <div className="flex flex-col gap-6">
      {activeTab === QUALIFIED_LEADS ? (
        <PartnerAccountUpdateStatus
          accountStatus={accountStatus}
          selectedRows={selectedRows}
          onUpdateSuccess={() => setRowSelection({})}
        />
      ) : null}

      {unlocked ? (
        <>
          <PartnersInterestedPrograms />

          <PartnersAccountInfo
            primaryContact={account?.profiles[0]}
            accountStatus={accountStatus}
          />

          <PartnersAccountProfiles
            profiles={account?.profiles}
            accountStatus={accountStatus}
          />
        </>
      ) : null}

      <PartnersAccountAttestations
        attestations={account?.attestations}
        accountStatus={accountStatus}
      />
    </div>
  )
}

export default PartnersAccount
