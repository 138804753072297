import { QueryClient, UseMutationResult } from "@tanstack/react-query"

import { useMutationWithRefresh } from "../utils"
import { getProjectTablePageData } from "../../../api/data"
import { UseMutationWithRefreshConfig } from "../utils/useMutationWithRefresh"
import { ELIGIBLE_LEADS } from "../../../shared/constants"

export function usePartnersTablePagination<
  TData,
  TError,
  TVariables extends string,
>(
  queryClient: QueryClient,
  config: UseMutationWithRefreshConfig<TData, TError, TVariables> = {}
): UseMutationResult<TData, TError, TVariables, unknown> {
  return useMutationWithRefresh<TData, TError, TVariables>(
    queryClient,
    (url: TVariables) => getProjectTablePageData(url),
    {
      mutationKey: ["partners", ELIGIBLE_LEADS],
      _onSuccess: (data: TData) => {
        queryClient.setQueryData(["partners", ELIGIBLE_LEADS], data)
      },
      ...config,
    }
  )
}
