import { CSVLink } from "react-csv"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faArrowDownToBracket } from "@fortawesome/pro-duotone-svg-icons"

const ExportCSV = ({ csvData }) => (
  <CSVLink
    className="btn2 btn2-outline-primary flex items-center gap-1 text-charcoal-500 hover:!text-charcoal-500 px-2"
    filename="partners.csv"
    data={csvData}
  >
    Export CSV
    <FontAwesomeIcon
      icon={faArrowDownToBracket}
      style={{
        "--fa-primary-opacity": 1,
        "--fa-secondary-opacity": 1,
      }}
    />
  </CSVLink>
)

export default ExportCSV
