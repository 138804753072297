import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faMinus } from "@fortawesome/pro-solid-svg-icons"

const TableFiltersRemoveFilter = ({
  filter,
  filters,
  currInput,
  setFilters,
}) => (
  <button
    type="button"
    className="w-5 h-5 transition-colors duration-200 rounded hover:bg-cloud"
    onClick={() => {
      currInput?.setFilterValue("")
      setFilters(filters.filter((prevFilter) => prevFilter !== filter))
    }}
  >
    <FontAwesomeIcon icon={faMinus} />
  </button>
)

export default TableFiltersRemoveFilter
