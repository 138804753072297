import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faFilter } from "@fortawesome/pro-duotone-svg-icons"
import { PopoverDisclosure } from "reakit/Popover"
import { Tooltip, TooltipArrow, TooltipReference } from "reakit/Tooltip"
import cx from "classnames"

const TableFiltersClose = ({ popover, tooltip, columnFiltersLength }) => {
  const filterText = columnFiltersLength > 1 ? "Filters" : "Filter"

  return (
    <PopoverDisclosure
      {...popover}
      className={cx(
        "btn2 btn2-outline-primary text-charcoal-500 hover:!text-charcoal-500 px-2",
        {
          "bg-cloud": columnFiltersLength > 0,
        }
      )}
    >
      <TooltipReference {...tooltip} className="flex items-center gap-1">
        Filter
        <FontAwesomeIcon
          icon={faFilter}
          style={{
            "--fa-primary-opacity": 0,
            "--fa-secondary-opacity": 1,
          }}
        />
      </TooltipReference>

      {!popover.visible && columnFiltersLength > 0 ? (
        <Tooltip {...tooltip} className="st-tooltip">
          <div className="text-sm max-w-sm p-3 st-tooltip-inner">
            <TooltipArrow {...tooltip} />
            {columnFiltersLength} {filterText}
          </div>
        </Tooltip>
      ) : null}
    </PopoverDisclosure>
  )
}

export default TableFiltersClose
