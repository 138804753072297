import { QueryClient, UseMutationResult } from "@tanstack/react-query"
import { useMutationWithRefresh } from "../utils"
import { getPartnersLandownerGeodownload } from "../../../api/data"
import { UseMutationWithRefreshConfig } from "../utils/useMutationWithRefresh"

export const useGetPartnersLandownerGeodownload = <TData, TError, TVariables>(
  queryClient: QueryClient,
  apId: number,
  config: UseMutationWithRefreshConfig<TData, TError, TVariables> = {}
): UseMutationResult<TData, TError, TVariables, unknown> => {
  return useMutationWithRefresh<TData, TError, TVariables, unknown>(
    queryClient,
    () => getPartnersLandownerGeodownload(apId.toString()),
    {
      onSuccess: (data) => {
        queryClient.setQueryData(["partners", "ap", apId, "geodownload"], data)
      },
      ...config,
    }
  )
}
