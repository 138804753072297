import { useContext } from "react"
import { useQueryClient } from "@tanstack/react-query"
import { Formik, Form } from "formik"

import { SubmitButton } from "../../components/SubmitButton"
import { Toast } from "../../components/Toast"
import { genericErrMsg } from "../../api/auth"
import { useUnlockDisqualifyAccountProjectReachout } from "../../hooks"
import { PartnersContext } from "../../context/PartnersContext"
import { PARTNERS_ACTIONS } from "../../shared/constants"

const PartnersEligibleLeadsUnlockDisqualify = ({
  action,
  handleSelectRow,
  reachoutsCounter,
}) => {
  const queryClient = useQueryClient()

  const {
    state: { activeRow },
    dispatch,
  } = useContext(PartnersContext)

  const { mutateAsync: unlockDisqualifyPartnersProjectEligibleLead } =
    useUnlockDisqualifyAccountProjectReachout(queryClient, activeRow?.id, {
      onSuccess: () => {
        Toast.success(
          action === "unlock"
            ? "Lead has been unlocked successfuly"
            : "Lead has been disqualified successfuly. Additional unlock available."
        )

        if (action === "unlock") {
          dispatch({
            type: PARTNERS_ACTIONS.setEligibleActiveRowUnlocked,
            payload: true,
          })
        } else {
          handleSelectRow(null)
        }
      },
      onError: (error) => {
        Toast.error(error?.response?.data?.message || genericErrMsg)
      },
    })

  const handleOnSubmit = async (values, { resetForm }) => {
    await unlockDisqualifyPartnersProjectEligibleLead(values)
    resetForm()
  }

  if (activeRow === null) {
    return null
  }

  return (
    <div className="relative w-full card card-content self-start overflow-hidden">
      <h4 className="sticky -top-4 left-0 right-0 bg-cloud-50 text-overline text-dusk-500 p-4 -m-4 mb-0 border-b-1 border-b-cloud z-2">
        {action === "unlock" ? "Unlock" : "Disqualify"}
      </h4>

      {action === "disqualify" ? (
        <p className="label leading-18px font-normal mt-4">
          Not interested? Disqualify landowners to hide them from the table and
          access another unlock.
        </p>
      ) : null}

      <Formik
        initialValues={{
          action,
        }}
        validateOnBlur={true}
        validateOnChange={true}
        onSubmit={handleOnSubmit}
        enableReinitialize
      >
        {(formikProps) => (
          <Form>
            <SubmitButton
              className="btn2 btn2-primary btn2-block font-semibold mt-4"
              isSubmitting={formikProps.isSubmitting}
              disabled={
                action === "unlock" && reachoutsCounter.unlocks_remaining < 1
              }
            >
              {action === "unlock" ? "Unlock" : "Disqualify"}
            </SubmitButton>
          </Form>
        )}
      </Formik>

      {action === "unlock" ? (
        <p className="text-sm text-dusk text-right leading-130 tracking-0.14 mt-4">
          Uses remaining: {reachoutsCounter.unlocks_remaining}
        </p>
      ) : null}
    </div>
  )
}

export default PartnersEligibleLeadsUnlockDisqualify
