import { useContext } from "react"
import { useQueryClient } from "@tanstack/react-query"
import cx from "classnames"

import PartnersQualifiedLeads from "./PartnersQualifiedLeads"
import PartnersEligibleLeads from "./PartnersEligibleLeads"
import { useGetPartnersLandowner } from "../../hooks"
import { PartnersContext } from "../../context/PartnersContext"
import {
  ELIGIBLE_LEADS,
  PARTNERS_ACTIONS,
  QUALIFIED_LEADS,
} from "../../shared/constants"

const TabItem = ({ text, currentTab, activeTab, onClick }) => (
  <button
    className={cx(
      "text-overline normal-case py-6 relative focus:z-[2] first:rounded-tl last:rounded-tr",
      "text-base font-bold leading-130 tracking-0.32 normal-case px-6 pt-4 pb-2",
      currentTab === activeTab
        ? "text-white bg-grass-500 border-b-8 border-grass-900"
        : "text-dusk hover:text-dusk-900 border-b-4 border-transparent transition duration-200"
    )}
    type="button"
    onClick={onClick}
  >
    {text}
  </button>
)

const PartnersTabs = ({
  partnerDashboardData,
  profile,
  eligibleLeads,
  inviteDealOwnerDialog,
  editDealOwnerDialog,
  reachoutsCounter,
}) => {
  const queryClient = useQueryClient()

  const {
    state: { activeTab, activeRow, eligibleActiveRowUnlocked },
    dispatch,
  } = useContext(PartnersContext)

  const showReachoutTab = reachoutsCounter?.max_reachouts > 0

  const { data: account, status: accountStatus } = useGetPartnersLandowner(
    queryClient,
    activeRow?.id,
    {
      enabled:
        activeTab === ELIGIBLE_LEADS
          ? eligibleActiveRowUnlocked && activeRow?.id !== undefined
          : activeRow?.id !== undefined,
    }
  )

  return (
    <>
      {profile?.is_partner ? (
        <div className="flex mb-6">
          <div className="self-start flex card-content rounded-t">
            <TabItem
              currentTab={QUALIFIED_LEADS}
              activeTab={activeTab}
              text="Interested Landowners"
              onClick={() => {
                dispatch({
                  type: PARTNERS_ACTIONS.setActiveTab,
                  payload: QUALIFIED_LEADS,
                })
                dispatch({
                  type: PARTNERS_ACTIONS.setActiveRow,
                  payload: partnerDashboardData[0],
                })
              }}
            />

            {showReachoutTab && (
              <TabItem
                currentTab={ELIGIBLE_LEADS}
                activeTab={activeTab}
                text="Send Landowner Invitations"
                onClick={() => {
                  dispatch({
                    type: PARTNERS_ACTIONS.setActiveTab,
                    payload: ELIGIBLE_LEADS,
                  })
                }}
              />
            )}
          </div>
        </div>
      ) : null}

      <PartnersQualifiedLeads
        partnerDashboardData={partnerDashboardData}
        profile={profile}
        inviteDealOwnerDialog={inviteDealOwnerDialog}
        editDealOwnerDialog={editDealOwnerDialog}
        account={account}
        accountStatus={accountStatus}
      />

      {profile?.is_partner ? (
        <PartnersEligibleLeads
          eligibleLeads={eligibleLeads}
          userFirstName={profile?.first_name}
          account={account}
          accountStatus={accountStatus}
          reachoutsCounter={reachoutsCounter}
        />
      ) : null}
    </>
  )
}

export default PartnersTabs
