import { useState, useEffect } from "react"
import debounce from "lodash/debounce"

const TableFiltersInput = ({
  filter,
  currInput,
  wideSelectHideInput,
  columnFiltersLength,
}) => {
  const [value, setValue] = useState(currInput?.getFilterValue() || "")
  const debounceOnChange = currInput && debounce(currInput?.setFilterValue, 250)

  useEffect(() => {
    setValue(value)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currInput.columnDef.filterFn])

  useEffect(() => {
    if (columnFiltersLength === 0) {
      setValue("")
    }
  }, [columnFiltersLength])

  if (wideSelectHideInput) {
    return null
  }

  if (filter === "interest_date") {
    return (
      <input
        type="date"
        id={`input-${filter}`}
        name={`input-${filter}`}
        className="form-input st-input col-start-3 col-end-4"
        value={currInput?.getFilterValue() || ""}
        onChange={(e) => currInput?.setFilterValue(e.target.value)}
      />
    )
  }

  return (
    <input
      id={`input-${filter}`}
      name={`input-${filter}`}
      className="form-input st-input col-start-3 col-end-4"
      value={value || ""}
      onChange={(e) => {
        setValue(e.target.value)
        debounceOnChange(e.target.value)
      }}
      autoComplete="off"
      disabled={!currInput}
    />
  )
}

export default TableFiltersInput
