import { QueryClient, UseQueryResult } from "@tanstack/react-query"

import { useQueryWithRefresh } from "../utils"
import { getPartnersDashboard } from "../../../api/data"
import { UseQueryWithRefreshConfig } from "../utils/useQueryWithRefresh"

export const useGetPartnersDashboard = <TData, TError>(
  queryClient: QueryClient,
  config: UseQueryWithRefreshConfig<TData, TError> = {}
): UseQueryResult<TData, TError> => {
  return useQueryWithRefresh<TData, TError>(
    queryClient,
    ["partners"],
    () => getPartnersDashboard(),
    config
  )
}
