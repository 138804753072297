import { flexRender } from "@tanstack/react-table"
import { useSortable } from "@dnd-kit/sortable"
import { CSS } from "@dnd-kit/utilities"
import cx from "classnames"

const DragAlongCell = ({ cell, i, arr }) => {
  const { isDragging, setNodeRef, transform } = useSortable({
    id: cell.column.id,
  })

  const style = {
    transform: CSS.Translate.toString(transform), // translate instead of transform to avoid squishing
    transition: "width transform 0.2s ease-in-out",
    width: cell.column.getSize(),
    zIndex: isDragging ? 1 : 0,
  }
  const isCheckboxCell = cell.column.columnDef.id === "select"

  return (
    <td
      ref={setNodeRef}
      className={cx(
        "flex items-center relative border-b-1 border-b-cloud px-3 py-3 lg:px-5 lg:py-4",
        { "md:!pl-6": i === 0 },
        { "md:!pr-6": i === arr.length - 1 },
        { "z-[1] opacity-80": isDragging }
      )}
      style={style}
      // DEV: stop the parent event handler (single row select) if we are checking a checkbox for bulk select
      onClick={(e) => {
        isCheckboxCell ? e.stopPropagation() : () => {}
      }}
    >
      {flexRender(cell.column.columnDef.cell, cell.getContext())}
    </td>
  )
}

export default DragAlongCell
