import cx from "classnames"

import { dateFns, numberFns, textFns } from "./constants"

const TableFiltersSelectFilterFn = ({
  wideSelectHideInput,
  filter,
  filterFunctions,
  setFilterFunctions,
  currInput,
}) => {
  let OPTIONS = textFns

  if (filter === "id" || filter === "acreage" || filter === "eligible_acres") {
    OPTIONS = numberFns
  }

  if (filter === "interest_date") {
    OPTIONS = dateFns
  }

  return (
    <select
      className={cx(
        "form-select form-input st-input cursor-pointer col-start-2 ",
        wideSelectHideInput ? "col-end-4" : "col-end-3"
      )}
      onChange={(e) => {
        setFilterFunctions({
          ...filterFunctions,
          [filter]: e.target.value,
        })

        if (e.target.value === "isEmpty" || e.target.value === "isNotEmpty") {
          currInput?.setFilterValue(" ")
        } else {
          currInput?.setFilterValue("")
        }
      }}
    >
      {OPTIONS.map(({ value, label }) => (
        <option key={value} value={value}>
          {label}
        </option>
      ))}
    </select>
  )
}

export default TableFiltersSelectFilterFn
