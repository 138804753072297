import { useMemo } from "react"

import { EligibleLeadTypes } from "@/types/partners"

export function usePartnersTableResultsText(
  count: number = 0,
  previous: string | null = null,
  next: string | null = null,
  results: EligibleLeadTypes[] = []
) {
  const partnersTableResultsText = useMemo(() => {
    const resultsPerPage = results?.length || 200
    const totalResults = count

    let currentPage

    if (previous === null) {
      currentPage = 1
    } else if (next === null) {
      currentPage = Math.ceil(totalResults / resultsPerPage)
    } else {
      currentPage =
        parseInt(new URL(next).searchParams.get("page") as string) - 1
    }

    const startIndex = (currentPage - 1) * resultsPerPage + 1
    const endIndex = Math.min(currentPage * resultsPerPage, totalResults)

    return `${startIndex}-${endIndex} / ${totalResults} results`
  }, [count, previous, next, results])

  return partnersTableResultsText
}
