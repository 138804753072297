import cx from "classnames"

const TableFiltersSelectColumn = ({
  filter,
  filters,
  setFilters,
  filterIndex,
  filterFunctions,
  setFilterFunctions,
  currInput,
  allColumns,
}) => (
  <select
    className="form-select form-input st-input cursor-pointer col-start-1 col-end-2"
    onChange={(e) => {
      const updatedFilters = [...filters]
      updatedFilters[filterIndex] = e.target.value
      setFilters(updatedFilters)
      setFilterFunctions({
        ...filterFunctions,
        [filter]: "includesString",
      })
      currInput?.setFilterValue("")
    }}
    value={filter}
  >
    {allColumns
      .filter((column) => column.id !== "qualified_status") // DEV: temporarily disable filtering by qualified_status
      .map((column) => {
        const disabled = filter !== column.id && filters.includes(column.id)

        if (column.id === "id") {
          return null
        }

        return (
          <option
            key={column.id}
            className={cx({
              "text-gray2 cursor-default": disabled,
            })}
            value={column.columnDef.accessorKey}
            disabled={disabled}
          >
            {column.columnDef.header}
          </option>
        )
      })}
  </select>
)

export default TableFiltersSelectColumn
