import { useContext, useState, useMemo } from "react"

import NothingFound from "../../components/NothingFound"
import PartnersAccount from "./PartnersAccount"
import PartnersAccountMap from "./PartnersAccountMap"
import PartnersTable from "./PartnersTable"
import { qualifiedTableColumns } from "./PartnersTable/helpers"
import { PartnersContext } from "../../context/PartnersContext"
import { PARTNERS_ACTIONS, QUALIFIED_LEADS } from "../../shared/constants"

const PartnersQualifiedLeads = ({
  partnerDashboardData,
  profile,
  inviteDealOwnerDialog,
  editDealOwnerDialog,
  account,
  accountStatus,
}) => {
  const {
    state: {
      activeTab,
      qualifiedLeadSorting,
      qualifiedLeadColumnFilters,
      qualifiedLeadFilters,
      qualifiedLeadFilterFunctions,
    },
    dispatch,
  } = useContext(PartnersContext)

  const [rowSelection, setRowSelection] = useState({})

  const columns = useMemo(
    () =>
      qualifiedTableColumns(
        qualifiedLeadFilterFunctions,
        inviteDealOwnerDialog,
        editDealOwnerDialog,
        dispatch,
        profile
      ),
    [
      qualifiedLeadFilterFunctions,
      inviteDealOwnerDialog,
      editDealOwnerDialog,
      dispatch,
      profile,
    ]
  )

  if (activeTab === QUALIFIED_LEADS && partnerDashboardData?.length === 0) {
    return (
      <NothingFound
        title="No qualified leads found"
        text="There are no qualified leads associated with this partner account yet."
      />
    )
  }

  if (activeTab !== QUALIFIED_LEADS) {
    return null
  }

  return (
    <div className="grid grid-cols-1 md:gap-6 md:grid-cols-3">
      <div className="col-span-2 partners-table qualified-leads">
        <PartnersTable
          data={partnerDashboardData}
          onRowClick={(row) => {
            dispatch({
              type: PARTNERS_ACTIONS.setActiveRow,
              payload: row,
            }),
              dispatch({
                type: PARTNERS_ACTIONS.setViewport,
                payload: null,
              })
          }}
          filterFunctions={qualifiedLeadFilterFunctions}
          setFilterFunctions={(val) => {
            dispatch({
              type: PARTNERS_ACTIONS.setQualifiedLeadFilterFunctions,
              payload: val,
            })
          }}
          columnFilters={qualifiedLeadColumnFilters}
          setColumnFilters={(val) => {
            dispatch({
              type: PARTNERS_ACTIONS.setQualifiedLeadColumnFilters,
              payload: val,
            })
          }}
          filters={qualifiedLeadFilters}
          setFilters={(val) =>
            dispatch({
              type: PARTNERS_ACTIONS.setQualifiedLeadFilters,
              payload: val,
            })
          }
          columns={columns}
          profile={profile}
          sorting={qualifiedLeadSorting}
          setSorting={(val) =>
            dispatch({
              type: PARTNERS_ACTIONS.setQualifiedLeadSorting,
              payload: val,
            })
          }
          inviteDealOwnerDialog={inviteDealOwnerDialog}
          editDealOwnerDialog={editDealOwnerDialog}
          rowSelection={rowSelection}
          setRowSelection={setRowSelection}
        />

        <PartnersAccountMap
          bounds={account?.bounds}
          accountStatus={accountStatus}
        />
      </div>

      <PartnersAccount
        account={account}
        accountStatus={accountStatus}
        selectedRows={rowSelection}
        setRowSelection={setRowSelection}
      />
    </div>
  )
}

export default PartnersQualifiedLeads
