import { QueryClient, UseMutationResult } from "@tanstack/react-query"
import { FormikValues } from "formik"
import find from "lodash/find"

import { useMutationWithRefresh } from "../utils"
import { partnersBulkUpdateLandownerStatus } from "../../../api/data"
import { UseMutationWithRefreshConfig } from "../utils/useMutationWithRefresh"
import { RowsType } from "@/types/partners"

export const usePartnersBulkUpdateLandownerStatus = <
  TData extends RowsType,
  TError,
  TVariables extends FormikValues,
>(
  queryClient: QueryClient,
  config: UseMutationWithRefreshConfig<TData, TError, TVariables> = {}
): UseMutationResult<TData, TError, TVariables, unknown> => {
  return useMutationWithRefresh<TData, TError, TVariables, unknown>(
    queryClient,
    (values: TVariables) => partnersBulkUpdateLandownerStatus(values),
    {
      _onSuccess: (data: RowsType) => {
        queryClient.setQueryData(["partners"], (oldData: RowsType) => {
          return oldData.map((landowner) => {
            const updatedLOdata = find(data, (lo) => lo.id === landowner.id)
            return updatedLOdata || landowner
          })
        })
      },
      ...config,
    }
  )
}
