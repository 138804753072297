import { useQueryClient } from "@tanstack/react-query"
import { Form, Formik } from "formik"

import { Modal } from "../../../components/Modal"
import { InputSelect } from "../../../components/MultiSelect"
import { SubmitButton } from "../../../components/SubmitButton"
import { ButtonPair } from "../../../components/ButtonPair"
import { Toast } from "../../../components/Toast"
import {
  useDeletePartnerAssignees,
  useUpdatePartnerAssignees,
} from "../../../hooks"
import { genericErrMsg } from "../../../api/auth"
import { isValidNewEmail } from "./helpers"

const EditDealOwnerModal = ({
  dialog,
  dealOwnerRowId,
  partnerAssignees,
  dealOwnerPartner,
}) => {
  const queryClient = useQueryClient()

  const { mutateAsync: updatePartnerAssignees } = useUpdatePartnerAssignees(
    queryClient,
    dealOwnerRowId,
    {
      onSuccess: () => {
        dialog.hide()
        Toast.success("Deal owner has been changed successfully.")
      },
      onError: (error) => {
        Toast.error(error?.message || genericErrMsg)
      },
    }
  )

  const { mutateAsync: deletePartnerAssignees } = useDeletePartnerAssignees(
    queryClient,
    dealOwnerRowId,
    {
      onSuccess: () => {
        dialog.hide()
        Toast.success("Deal owner has been removed successfully.")
      },
      onError: (error) => {
        Toast.error(error?.message || genericErrMsg)
      },
    }
  )

  const handleEditDealOwner = async (values) => {
    if (values.email === null) {
      await deletePartnerAssignees({
        email: dealOwnerPartner?.deal_owner?.email,
      })
    } else {
      await updatePartnerAssignees({ email: values.email.value })
    }
  }

  return (
    <Modal
      header="Edit Deal Owner"
      aria-label="Edit Deal Owner"
      dialog={dialog}
    >
      <p className="text-charcoal-500 text-base leading-130 tracking-0.32 mb-6">
        To make a change to this deal owner, update their email address or
        remove and reassign later.
      </p>

      <Formik
        initialValues={{
          email: {
            value: dealOwnerPartner?.deal_owner?.email || "",
            label: dealOwnerPartner?.deal_owner?.email || "",
          },
        }}
        onSubmit={handleEditDealOwner}
        enableReinitialize
      >
        {(formikProps) => (
          <Form>
            <InputSelect
              className="w-[304px] max-w-full"
              labelClass="label font-bold text-charcoal-500 leading-18px mb-2"
              aria-label="Email address"
              label="Email address"
              name="email"
              placeholder="Enter email address of deal owner"
              options={partnerAssignees}
              isValidNewOption={isValidNewEmail}
              isClearable
            />

            <ButtonPair
              className="mt-6"
              // eslint-disable-next-line react/no-unstable-nested-components
              primary={(primaryProps) => (
                <SubmitButton
                  disabled={!(formikProps.isValid && formikProps.dirty)}
                  isSubmitting={formikProps.isSubmitting}
                  {...primaryProps}
                >
                  Save Changes
                </SubmitButton>
              )}
              secondary={
                <button
                  type="button"
                  onClick={() => formikProps.setFieldValue("email", null)}
                >
                  Remove Deal Owner
                </button>
              }
            />
          </Form>
        )}
      </Formik>
    </Modal>
  )
}

export default EditDealOwnerModal
